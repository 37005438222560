

function InfoAction() {
  return (<>
    <div className="infoaction">
        InfoAction
    </div>
  </>)
}

export default InfoAction