import './Spinner.css';

function Spinner() {
    return (
      <div className='planit-loadingSpinnerContainer'>
        <div className='planit-loadingSpinner'></div>
      </div>
    )
  }
  
  export default Spinner
  