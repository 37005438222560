import Header from "../components/Header/Header"

function Settings() {
  return (<>
    <Header />
    <div>Settings</div>
  </>
  )
}

export default Settings