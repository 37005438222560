import React from "react";


function NavLogo(props){
    return(
        <>
            <img src={props.icon} id="STHlogo" alt="circular STHopwood logo - wooden"/>
        </>
    );
}

export default NavLogo;